import '../scss/index.scss';
import $ from 'jquery';

$(document).ready(function () {
    $('.categories-list a').each(function () {
        var category = window.location.pathname;
        if ($(this).attr('href') === category) {
            $(this).addClass('highlighted');
        }
    });

    // Mobile menu
    $('#mobileMenuButton').on('click', function () {
        $(this).toggleClass('open');
        $('.header-options nav').toggleClass('open');
    });

    $('.header-options nav ul li').each(function () {
        if ($(this).children('.dropdown__menu').length) {
            $(this).append('<i class="fa fa-plus"></i>');
        }
    });

    $(document).on('click', '.header-options nav ul li', function () {
        if ($(this).children('svg').hasClass('fa-minus')) {
            $(this).children('svg').addClass('fa-plus');
            $(this)
                .children('svg')
                .parent()
                .children('.dropdown__menu')
                .removeClass('opened');
        } else {
            $(this).children('svg').addClass('fa-minus');
            $(this)
                .children('svg')
                .parent()
                .children('.dropdown__menu')
                .addClass('opened');
        }
    });
});

// Show skip to main content on tab press and show sub menu options in nav
$(document).keyup(function (e) {
    if (e.key === 'Tab') {
        if ($('#skipToMain:focus').length) {
            $('#skipToMain').removeClass('element-invisible');
            $('.menu-dropdown').attr('aria-hidden', 'true');
            $('.menu-dropdown').attr('aria-expanded', 'false');
        } else if ($(':focus').parent().children('.menu-dropdown').length) {
            $(':focus')
                .parent()
                .children('.menu-dropdown')
                .children('.inner')
                .children('h3')
                .addClass('focused');
            $(':focus')
                .parent()
                .children('.menu-dropdown')
                .children('.inner')
                .children('ul')
                .addClass('focused');
            $(':focus')
                .parent()
                .children('.menu-dropdown')
                .children('.inner')
                .children('ul')
                .attr('aria-expanded', 'true');
            $(':focus')
                .parent()
                .children('.menu-dropdown')
                .attr('aria-expanded', 'true');
            $(':focus')
                .parent()
                .children('.menu-item')
                .attr('aria-expanded', 'true');
            $('.menu-dropdown').attr('aria-hidden', 'false');
        } else if (
            $('header#header nav ul li .menu-dropdown .inner ul li a').is(
                ':focus'
            )
        ) {
            $(':focus').parent().parent().addClass('focused');
            $(':focus')
                .parent()
                .parent()
                .parent()
                .children('.sub-menu-heading')
                .addClass('focused');
            $(':focus').parent().parent().attr('aria-expanded', 'true');
            $('.menu-dropdown').attr('aria-hidden', 'false');
        } else if (
            $(
                'header#header nav ul li .menu-dropdown .inner .sub-menu-heading a'
            ).is(':focus')
        ) {
            $(':focus').parent().addClass('focused');
            $(':focus').parent().parent().children('ul').addClass('focused');
            $(':focus')
                .parent()
                .parent()
                .children('ul')
                .attr('aria-expanded', 'true');
            $('.menu-dropdown').attr('aria-hidden', 'false');
        } else {
            $('#skipToMain').addClass('element-invisible');
            $('header#header nav ul li .menu-dropdown .inner h3').removeClass(
                'focused'
            );
            $('header#header nav ul li .menu-dropdown .inner ul').removeClass(
                'focused'
            );
            $('header#header nav ul li .menu-dropdown .inner ul').attr(
                'aria-expanded',
                'false'
            );
            $('.menu-dropdown').attr('aria-hidden', 'true');
            $('.menu-item-depth-1, .menu-dropdown').attr(
                'aria-expanded',
                'false'
            );
        }
    }
});

// Add aria attribute on submenu parent hover
$('header#header nav ul li').on('mouseenter', function () {
    if ($(this).children('.menu-dropdown').length) {
        $(this).children('a').attr('aria-expanded', 'true');
        $(this).children('.menu-dropdown').attr('aria-expanded', 'true');
        $(this).children('.menu-dropdown').attr('aria-hidden', 'false');
    }
});

$('header#header nav ul li').on('mouseleave', function () {
    if ($(this).children('.menu-dropdown').length) {
        $(this).children('a').attr('aria-expanded', 'false');
        $(this).children('.menu-dropdown').attr('aria-expanded', 'false');
        $(this).children('.menu-dropdown').attr('aria-hidden', 'true');
    }
});
